import "./App.css";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { IfcViewerAPI } from "web-ifc-viewer";
import {
  Backdrop,
  CircularProgress,
  ClickAwayListener,
  Fab,
  Paper,
  Typography,
} from "@material-ui/core";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import Dropzone from "react-dropzone";
import { IFCWALLSTANDARDCASE } from "web-ifc";
import PropertiesPopup from "./components/PropertiesPopup";

function App() {
  const [loaded, setLoaded] = useState(false);
  const [loadingIfc, setLoadingIfc] = useState(false);
  const [viewer, setViewer] = useState(null);
  const [selectedIfcItem, setSelectedIfcItem] = useState(null);

  const dropzoneRef = useRef();
  const ifcContainer = useRef();

  useEffect(() => {
    if (ifcContainer.current) {
      const container = ifcContainer.current;
      const viewer = new IfcViewerAPI({ container });
      viewer.axes.setAxes();
      viewer.grid.setGrid();
      viewer.IFC.setWasmPath("../../");
      setViewer(viewer);
    }
  }, []);

  const onDrop = async (files) => {
    setLoadingIfc(true);
    await viewer.IFC.loadIfc(files[0], true);
    setLoadingIfc(false);
    setLoaded(true);
  };

  const handleClickOpen = () => {
    dropzoneRef.current.open();
  };

  const ifcOnDoubleClick = async (event) => {
    if (viewer) {
      const result = await viewer.IFC.pickIfcItem(false);
      if (result) {
        const props = await viewer.IFC.getProperties(
          result.modelID,
          result.id,
          true,
          true
        );

        const type = await viewer.IFC.loader.ifcManager.getIfcType(
          result.modelID,
          result.id
        );
        // convert props to record
        if (props) {
          let ifcRecords = [];
          ifcRecords["Entity Type"] = type;
          ifcRecords["GlobalId"] = props.GlobalId && props.GlobalId?.value;
          ifcRecords["Name"] = props.Name && props.Name?.value;
          ifcRecords["ObjectType"] =
            props.ObjectType && props.ObjectType?.value;
          ifcRecords["PredefinedType"] =
            props.PredefinedType && props.PredefinedType?.value;
          setSelectedIfcItem({ ...props, standard: ifcRecords });
        }
      }
    }
  };

  const ifcOnMouseMove = async (event) => {
    if (viewer) {
      viewer.IFC.selector.prePickIfcItem();
    }
  };

  return (
    <div className="App">
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <div style={{ flex: "1 1 auto", minWidth: 0 }}>
          <div
            ref={ifcContainer}
            style={{ position: "relative", height: "100%", width: "100%" }}
            onDoubleClick={ifcOnDoubleClick}
            onMouseMove={ifcOnMouseMove}
          />
        </div>
      </div>
      <Fab
        onClick={handleClickOpen}
        style={{ position: "absolute", top: "16px", left: "16px" }}
      >
        <FolderOpenOutlinedIcon />
      </Fab>
      <Dropzone onDrop={onDrop} ref={dropzoneRef}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
            </div>
          </section>
        )}
      </Dropzone>
      <PropertiesPopup ifcItem={selectedIfcItem} />
      <Backdrop
        style={{
          zIndex: 100,
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
        open={loadingIfc}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
}

export default App;
