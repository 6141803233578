import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Close } from "@material-ui/icons";
const PropertiesPopup = ({ ifcItem }) => {
  const [open, setOpen] = React.useState(ifcItem);

  useEffect(() => {
    setOpen(ifcItem);
  }, [ifcItem]);
  return (
    <div>
      {open && (
        <Card
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
          }}
        >
          <CardHeader
            title={ifcItem.standard["Name"]}
            action={
              <IconButton>
                <Close
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </IconButton>
            }
          />
          <CardContent>
            <React.Fragment>
              <Accordion elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Allgemein</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      {Object.keys(ifcItem.standard).map(
                        (key) =>
                          ifcItem.standard[key] && (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="body1">{key}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2">
                                  {ifcItem.standard[key]}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
              {ifcItem.psets &&
                ifcItem.psets.map((set, index) => (
                  <Accordion key={index} elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6">{set.Name.value}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableBody>
                          {set.HasProperties &&
                            set.HasProperties.map((prop, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Typography variant="body1">
                                    {prop.Name.value}
                                  </Typography>
                                </TableCell>
                                <TableCell style={{ maxWidth: "300px" }}>
                                  <Tooltip title={prop.NominalValue.value}>
                                    <Typography variant="body2" noWrap>
                                      {prop.NominalValue.value}
                                    </Typography>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </React.Fragment>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default PropertiesPopup;
